.csr-container{
    padding: 40px 0px;
}
.csr-text{
    color: var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    line-height: 33px;
    padding-top: 20px;
    text-align: justify;
}
.csr-background{
    margin-top: 30px;
}
.csr-img img{
    width: 90%;
    height: 230px;
    border-radius: 10px;
    opacity: 0.8;
    padding-bottom: 30px;
}
.csr-details{
    padding: 5px 0px 40px 0px;
}
.csr-background .row{
    padding-top: 40px;
}