.homepage{
    padding: 30px 0px;
    background:rgb(227, 236, 227);
 
}
.home-card{
    position: relative;
    margin: 40px 0px;
}
.home-card-content{
    text-align: center;
    color:#fff;
    font-family: var(--textFamily);
}
.home-card-content .card{
    padding: 20px 0px;
    height: 360px;
    transition: 0.4s all ease
}
.home-card-content .card:hover{
    opacity: 0.7
}
.home-card-content .card1{
    background: var(--primaryColor)
}
.home-card-content .card2{
    background-color:  #2b479c 
}
.home-card-content .card3{
    background: rgb(37, 88, 37);
}
.card-body .card-icon{
    font-size: 30px;
}
.card-body .card-title{
    padding: 20px 0px 10px 0px;
    font-size: 20px;
    font-weight: 600
}
.card-body .card-text{
    font-size: 18px;
    line-height: 32px
}
.card-body .btn{
    margin-top: 15px;
    border: 2px solid #fff;
    color:#fff;
    font-family: var(--textFamily);
    background: transparent;
    transition: 0.4s all ease;
}
.card-body .btn:hover{
    border: 2px solid rgb(226, 224);
    background: rgb(226, 224, 224);
    color: var(--blackColor)
}
.home-about-text{
    color:var(--blackColor);
    line-height: 30px;
    font-family: var(--textFamily);
    font-size: 17px;
    padding: 30px 0px 30px 5px;
    text-align: justify;
}
.home-about-text2{
    padding-top: 105px;
}

/* home flagship css starts here */
.home-flagship-container{
    padding: 30px 0px;
}
.home-flagship-container h1{
    text-align: center;
    justify-content: center;
}
.title-underline{
    padding-left: 37vw;
}
.home-flagship-subtitle{
    font-size: 25px;
    color: var(--blackColor);
    font-family: var(--headingFamily);
}
.home-flagship-row{
    padding: 40px 0px 20px 0px;
}
.home-text{
    color:var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    line-height: 30px;
    padding: 10px 0px;
    text-align: justify;
}
.home-flagship-image-section{
    padding: 0px 30px;
    position: relative;
}
.home-flagship-image img{
    border-radius: 10px;
    width: 100%;
    height: 300px;
}
.home-flagship-image-text{
    position: absolute;
    text-align: right;
    font-family: var(--textFamily);
    color: #fff;
    font-size: 20px;
    top:-0px;
    padding-right: 45px;
    right:0px;
}
.home-flagship-image-text img{
   width: 60%;
   text-align: right;
   top:-30px;
   position: relative;
}

/* home service css starts here */
.home-service-container{
    background:#fff;
    padding: 30px 0px;
    margin: 20px 0px 30px 0px
}
.home-service h1{
    text-align: center;
    justify-content: center;
}
.home-service-text{
    color: var(--blackColor);
    font-family: var(--textFamily);
    text-align:center;
    max-width: 1050px;    
    font-size: 18px;
    padding-left: 10vw;
    padding-bottom: 40px;
    padding-top: 20px;
}
.home-service-row{
    padding: 20px 0px 30px 0px;
}
.home-service-grid-style{  
    justify-content: center!important;
    padding:20px 5px;
    height: 170px;
    cursor:pointer;
    transition: 0.4s all ease;
    opacity:1;
    position:relative;
}
.home-service-nav{
    text-decoration: none;
}
.home-service-grid-style:hover{
    opacity: 0.5;
    transform: scaleY(1.1);
}
.home-service-grid-style:hover img{
    transform: rotateY(180deg);
}
.home-service-grid-style1,.home-service-grid-style3 {
    background-color:  rgb(37, 88, 37);
}
.home-service-grid-style2, .home-service-grid-style4{
    background-color: #2b479c;
}
.home-service-grid-style .img{
    background: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:0 auto; 
    transition: 0.4s all ease;  
}
.home-service-grid-style img{
     height:50px;
     width: 50px;
     transition: 0.4s all ease; 
}
.home-service-grid-style h4{
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
    color:#fff;
    padding: 20px 10px 10px 10px;
    font-family: var(--textFamily);
}
/* another section starts here */
.home-picture{
    background:var(--mainBlue);
    padding: 50px 0px;
    color:#fff;
    font-family: var(--textFamily);
}
.home-picture img{
    width:100%;
    padding-bottom: 10px;
}
/* .home-signup something  start here*/
.home-container-signup{
    padding:30px 200px;
}
.home-signup-title h1{
    color:#fff;   
}
.home-signup{
    border: 2px solid var(--mainBlue);
    padding: 30px 30px;  
    text-align: center;
    border-radius: 10px;
}

/* home-contact starts here */
.home-contact{
    background:var(--primaryColor);
    padding: 30px 0px;
    color:#fff
}
.home-contact .home-text{
    color:#fff
}
.home-contact .button-navlink .nav{
    border:2px solid  var(--mainBlue);
    color: #fff;
   
}
.home-contact .button-navlink .nav:hover{
    background: var(--mainBlue);
    border:2px solid transparent;
    color: #fff;
}
.home-contact-container{
    background: var(--mainBlue);
    text-align: center;
    color:#fff; 
    font-family:var(--textFamily) ;
    text-transform: capitalize;
    padding-bottom: 10px; 
}
.home-contact-container h3{
    padding: 20px 0px;
    line-height: 50px;   
}
.home-contact-container h6{
    padding-bottom: 15px;
}
.home-contact-container .nav{
    color:var(--primaryColor);
}
.button-navlink span{
    padding-left: 15px;
    font-size: 20px;
    transition: 0.4s all ease;
}

/* media screen css */

@media screen and (max-width: 960px){
    .home-container-signup{
        padding:30px 100px;
    }
    .home-about-text2{
        padding-top: 10px;
    }
    .home-card{
        position: relative;
        top: 0px;
    }
    .home-card-content .card{
        padding: 20px 0px;
        height:auto;
    }
}

@media screen and (max-width: 760px){
    .home-container-signup{
        padding:30px 20px;
    }
    .home-signup{
        border: 2px solid var(--mainBlue);
        padding: 30px 5px;  
        text-align: left;
        border-radius: 10px;
    }
    .home-signup-title h1{
        text-align: center;  
    }
    .home-signup .home-text{
        font-size: 15px;
    }
    .title-underline{
        padding-left: 34vw;
    }
  
}
