*{
    margin:0;
    padding:0;
}
:root{
    --blackColor: #404552;
    --black2Color:#8a8a8a;
    --primaryColor: #31b331;
    --mainBlue:#001659;
    --textFamily:'Lato', sans-serif;
    --headingFamily: 'Oswald', sans-serif;
    --backgroundColor: rgb(227, 236, 227);
    --green: #20ca20;
  
}
.page-container{
    padding: 0px 90px;
}
body h1{
    color:var(--textColor);
    font-size: 35px;
    font-family: var(--headingFamily);
    font-weight:bold;
    text-transform: capitalize;
}
@media screen and (max-width: 960px){
    .page-container{
        padding: 0px 30px;
        overflow:hidden;
    }
}
@media screen and (max-width: 760px){
    .page-container{
        padding: 0px 11px;
        overflow:hidden;
    }
    body h1{
        color:var(--textColor);
        font-size: 25px;
        font-family: var(--headingFamily);
        font-weight:bold;
        text-transform: capitalize;
    }
}
.button-navlink {
    display: flex;
    justify-content: center;
    text-align: center;  
}
.button-navlink .nav{
    border:2px solid  var(--primaryColor);
    border-radius: 4px;
    padding: 10px 25px;
    text-decoration: none;
    font-family:   var(--textFamily);
    color: var(--blackColor);
    font-weight: 500;
    font-size: 20px;
    transition: 0.4s all ease;
    background:transparent;
}
.button-navlink .nav:hover{
    background: var(--primaryColor);
    border:2px solid transparent;
    color: #fff;
}

