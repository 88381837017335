body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin:0;
    padding:0;
}
:root{
    --blackColor: #404552;
    --black2Color:#8a8a8a;
    --primaryColor: #31b331;
    --mainBlue:#001659;
    --textFamily:'Lato', sans-serif;
    --headingFamily: 'Oswald', sans-serif;
    --backgroundColor: rgb(227, 236, 227);
    --green: #20ca20;
  
}
.page-container{
    padding: 0px 90px;
}
body h1{
    color:var(--textColor);
    font-size: 35px;
    font-family: 'Oswald', sans-serif;
    font-family: var(--headingFamily);
    font-weight:bold;
    text-transform: capitalize;
}
@media screen and (max-width: 960px){
    .page-container{
        padding: 0px 30px;
        overflow:hidden;
    }
}
@media screen and (max-width: 760px){
    .page-container{
        padding: 0px 11px;
        overflow:hidden;
    }
    body h1{
        color:var(--textColor);
        font-size: 25px;
        font-family: 'Oswald', sans-serif;
        font-family: var(--headingFamily);
        font-weight:bold;
        text-transform: capitalize;
    }
}
.button-navlink {
    display: flex;
    justify-content: center;
    text-align: center;  
}
.button-navlink .nav{
    border:2px solid  #31b331;
    border:2px solid  var(--primaryColor);
    border-radius: 4px;
    padding: 10px 25px;
    text-decoration: none;
    font-family:   'Lato', sans-serif;
    font-family:   var(--textFamily);
    color: #404552;
    color: var(--blackColor);
    font-weight: 500;
    font-size: 20px;
    transition: 0.4s all ease;
    background:transparent;
}
.button-navlink .nav:hover{
    background: #31b331;
    background: var(--primaryColor);
    border:2px solid transparent;
    color: #fff;
}


.footer-logo{
    justify-content: center;
    text-align: center;
    padding: 20px 80px;
}

.footer-logo img{
    width: 100%;
    height: 150px
}
.footer-address{
    display: flex;
    font-family: var(--textFamily);
    font-size: 17px;
    color: var(--blackColor);
    padding-bottom: 0px;
}
.footer-address1{
    max-width: 600px;
    padding-left: 100px;
}
.footer-address2{
    padding-left: 12px;
}
.footer-address2 p .fa{
    color: var(--primaryColor);
    padding-right: 5px;
}
.footer-address2 p{
    font-weight: bold;
    font-size: 18px;
}
.footer-address2 p span{
    display: block;
    font-weight: lighter;
    font-size: 15px;
}
.footer-address2 p span a{
    text-decoration: none;
    color: var(--blackColor);
    font-size: 18px;
    transition: 0.4s all ease;
}
.footer-address2 p span a:hover{
    color: var(--primaryColor);
}
.footerpagelogo{
    padding: 0px 80px;
}
.footerpagelogo ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    text-align: center;
}
.footerpagelogo ul li{
    padding:   5px 10px 20px 10px;
    font-size: 40px;
}
.footerpagelogo ul li a{
    color: rgb(75, 75, 75);
    transition: 0.4s all ease;
}
.footerpagelogo ul li a:hover{
    color: var(--primaryColor)
}
.footerpage2{
    background-color: var(--blackColor);
    color: white;
    line-height: 30px;
    padding: 20px 0px;
}
.footerpage2 .container{
    font-size: 15px;
    font-family: 'Lato', sans-serif ;
}
.footerpage2 .footer-link{
    text-decoration: none;
    color: var(--primaryColor);
    transition: 0.4s all ease;
}
.footerpage2 .footer-link:hover{
    color:green
}
.footerpage2 .footer-span-link{
    padding-left: 20px;
    letter-spacing: 3px;
}
.footer-span-link a{
    color: white;
    padding: 0px 5px 0px 5px;
    text-decoration: none;
    transition: 0.4s all ease;
    letter-spacing: 0!important;
}
.footer-span-link a:hover{
    color: green;
}

@media screen and (max-width: 960px){  
    .footer-address{
        display: block;
        font-family: var(--textFamily);
        font-size: 17px;
        color: var(--blackColor);
        padding-bottom: 0px;
        text-align: center;
    }
    .footer-address1{
        padding-left: 0px;
        max-width:auto;
        padding-bottom: 20px;
    }
    .footer-logo{
        justify-content: center;
        text-align: center;
        padding: 0px;
    }
    .footer-logo img{
        width: 70%;
        height: 100px
    }
}
.navbar {
  display: flex;
  /* height: 120%; */
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background: #fff; 
  transition: 0.6s all ease;
}
.navbar.active{
  background: #fff;
  position: -webkit-sticky;
  position: fixed;
  top:0;
  width: 100%;
  height:100px;
  box-shadow: 0px 15px 10px -15px var(--mainBlue); 
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120%;
  height: 100px;
  max-width: 1500px;
  padding:0px 60px;

}

.nav-logo img{
  width: 100%;
  height: 200px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: var(--blackColor);
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-family: var(--textFamily);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
 
}
.nav-links:hover{
  color:var(--blackColor);
}
.nav-item {
line-height: 66px;
margin-right: 1rem;
margin-top:25px;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after  {
  width: 100%;
  background: var(--primaryColor);
}

.nav-item .active {
  color: var(--primaryColor); 
}

.nav-icon {
  display: none;
}
.span-arrow{
  display: none;
}
@media screen and (max-width: 960px) {
  .navbar {
    height:80px;
  }
  .navbar.active{
    height:80px;
  }
  .nav-container{
    height:80px;
  }
  .nav-logo img{
    width: 80%;
    height: 120px;
  }
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background:  var(--primaryColor);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    text-align:left;
    padding: 0px 30px 10px 30px;
    box-shadow:5px 0px 5px 5px #888888;
  }

  .nav-item:after {
    content: "";
    display: block;
    background: transparent;
    transition: none;
  }
  
  .nav-item:hover:after  {
    width: 100%;
    background: none;
  }
  .nav-item .active {
    color: #fff;
    border-bottom: 1px blue;
  }
  .nav-links {
    padding: 0rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--primaryColor);
  }
  .nav-item{
      line-height: 0px;
      top: 0px;
  }
  .sub-item{
      background-color: #399ca5;
      top: 100px;
      width: 60%;
      padding: 20px;
      text-align:right;
  }

  .nav-container {
    max-width: 100%;
    padding:0px 0px;
  
  }
  .drop-palava{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .span-arrow{
    display:inline;
  }
  .span-arrow .droparrow{
    font-size: 20px;
  }
  
}

/* css fro the dropdown start here */
.dropdown-span .nav-item-dropdown{
  color:var(--blackColor);
}
.dropdown-span .nav-item-dropdown:hover{
  color:var(--blackColor)
}
.dropdown-span .nav-item-dropdown:hover .nav-dropdown{
  display: block;
}
.nav-dropdown{
  position: absolute;
  top: 100px;
  background: rgb(227, 236, 227);
  padding:0;
  margin:0;
  z-index:999;
  display: none;
}

.nav-dropdown .nav-dropdown-nav{
  text-decoration: none;
  color:var(--blackColor);
  font-family: var(--textFamily);
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
  font-weight: 700;
  transition: 0.4s all ease;
  background: transparent;
  display: block;
  margin:0;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 45px;
}
.nav-dropdown .nav-dropdown-nav:hover{
  color:#fff;
  border-bottom: 1px solid green;
  background: rgb(164, 180, 164);
}
.otherpages-background{
    background-color: var(--black2Color);
    height: 300px;
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url('https://res.cloudinary.com/hayteetech/image/upload/v1640530975/Omakwaenergy/image2_n5mfnp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.otherpages-container{
    color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    position: relative;
    padding-top: 160px;
}
.other-page-heading{
    font-size: 40px;
    font-family: var(--textFamily);
    font-weight: 700;
    padding-left: 10px;
}
.other-pages-span{
    font-size: 20px;
    padding-top: 5px;
    color:rgb(221, 219, 219);
}
.other-pages-span .otherpage-navlink{
    color:#fff!important;
    text-decoration: none;
    padding-right: 10px;
    
}
.other-pages-span span{
    padding-left: 10px;
    font-size: 15px;
}
@media screen and (max-width:960px){
    .otherpages-container{
        color: #fff;
        display: block;
        text-align: left;
        position: relative;
        padding-top: 40px;
    }
    .other-page-heading{
        padding-left: 0px;
        font-size: 30px;
    }
    .other-pages-span{
        font-size: 20px;
        padding-top: 5px;
        color:rgb(221, 219, 219);
        text-align: right;
        padding-top: 100px;
        position: relative;
    }
}

.main-underline{
    justify-content: space-around;
    position: relative;
    top:15px;
}
.underline{
    display: flex;
}
.underline1, .underline2{
    border-top: 2px solid var(--primaryColor);
    width: 5vw;  
}
.underline-slant{
    position: relative;
    top:-13px;
    font-size: 16px;
    color: var(--primaryColor);
    left:-4px;
    font-weight: 700;
}
.underline-slant2{
    position: relative;
    color: var(--primaryColor);
    top:-13px;
    font-size: 16px;
    font-weight: 700;
    left: -4px;
}
.underline2{
    position: relative;
    left:-8px;
}
@media screen and (max-width: 960px){
    .underline1, .underline2{
        border-top: 2px solid var(--primaryColor);
        width: 8vw;  
    }
}
@media screen and (max-width: 760px){
    .underline1, .underline2{
        border-top: 2px solid var(--primaryColor);
        width: 12vw;  
    }
}

.about-whoweare{
    padding: 30px 0px;
    font-family: var(--textFamily);
    font-size: 17px;
    color:var(--blackColor);
    text-align: justify;
    line-height: 30px;
}
.about-whoweare h1{
    text-align: center;
}

.about-whoweare span{
    color: var(--primaryColor);
    font-weight: bold;
}
.about-whoweare-text{
    padding-bottom: 20px;
}

.hr-line hr{
    border-top: 2px solid var(--primaryColor);
}
.vision-mission-column{
    padding-top: 35px;
    padding-bottom: 30px;
}
.vision-mission-text{
    background-color: var(--primaryColor);
    border-left: 4px solid var(--mainBlue);
    color: #fff;
    border-radius: 4px;
    padding: 20px;
    font-size: 18px;
}


/* css for the about-purpose */
.aboutpurpose-container{
    padding: 40px 0px;
}
.about-purpose-values{
    padding: 10px 0px 30px 0px;
}
.about-purpose-text{
    color:var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    line-height: 30px;
    padding-top: 20px;
    text-align: justify;
}
.about-purpose-container{
    background: var(--backgroundColor);
    padding: 40px 0px;
}
.about-purpose-title .about-purpose-text{
    padding-top:5px;
}
.about-purpose-title h4{
    font-family:var(--textFamily);
    font-size: 22px;
    font-weight: bold;
}
.about-purpose-column{
    display: flex;
    justify-content: left;
    padding: 20px 0px;
}
.about-purpose-column img{
    width: 30%;
    height: 70px;
    padding-right: 30px;
}

/* about strategy page css starts here */
.strategy-container{
    padding: 50px 0px;
}
.about-strategy-container{
    padding: 40px 0px;
    display: flex;
}
.about-strategy-icon{
    text-align: center;
    padding-right: 30px;
    font-size: 15px;
}
.strategy-icon{
    width: 100px;
    height: 100px;
    color: #fff;
    background: var(--mainBlue);
    border-radius: 50%;
    padding: 20px;
    transition: 0.4s all ease;
}
.about-strategy-container h4{
    color:var(--blackColor);
    font-family: var(--textFamily);
    font-weight: bold;
    font-size: 22px;
}
.about-strategy-text{
    font-size: 17px;
    color:var(--blackColor);
    font-family: var(--textFamily);
    line-height: 30px;
}
.about-strategy-container:hover .strategy-icon{
    background: var(--primaryColor);
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg)
}

/* about partner css starts here */
.about-partner{
    padding: 50px 0px;
}
.about-partner-text{
    justify-content: center;
    text-align: center;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 17px;
    line-height: 30px;
    padding-top: 40px;
}
.partner-slide{
    /* margin: 0px 70px; */
    padding:  50px 0px;
    max-width: 500px;
    margin: auto!important;
    text-align: center;
}
.partner-slide img{
    /* width: 100%; */
}
.partner-slide .homeslide-arrow{
    display: flex;
    justify-content: space-between;
}
.aboutpage-card-container{
    padding: 20px 0px;
}
.aboutpage-card-container .card-title{
    font-size: 24px;
    font-family: var(--headingFamily);
    color:var(--blackColor);
    padding: 10px 0px 30px 0px;
}
.about-card-nav{
    text-decoration: none;
    transition:0.5s all ease;
}

.card-container{
    background: var(--backgroundColor);
    padding:30px 20px;
    color: var(--blackColor);
    font-family: var(--textFamily);
    transition:0.5s all ease;
    opacity: 0.7;
    height: 330px;
    border-bottom: 2px solid var(--mainBlue);
}
.card-container:hover{
    background: #Fff;
    z-index:999;
    box-shadow: 5px 0px 5px 5px #888888;
    opacity: 1;
    border-bottom: 2px solid var(--primaryColor);
}
.about-card-icon{
    text-align: center;
    position: relative;
    top:-25px;  
}
.about-icon-arrow{
    border-radius: 50%;
    background: rgb(243, 248, 238);
    color: var(--primaryColor);
    height: 50px;
    width:50px;
    font-size: 15px!important;  
    padding: 10px;
    transition: 0.5s all ease;
}
.card-background{
    color:var(--blackColor)
}
.card-background:hover .about-icon-arrow{
    background: var(--primaryColor);
    border:1px solid var(--mainBlue);
    color:#fff;
}
.card-container img{
    padding-bottom:20px;
}
.card-container h4{
    padding: 5px 0px 10px 0px;
    font-family: var(--textFamily);
    font-weight: bold;
    color: var(--blackColor);
    text-transform: capitalize;
    font-size: 18px;
}
.card-container p{
    font-size: 15px;
}
.contact-container{
    padding: 40px 0px;
}
.contact-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1648066466/Omakwaenergy/contactbg_bgn62i.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.contact-details{
    background-color: var(--mainBlue);
    color:#fff;
}
.contact-detailsOne{
    padding: 20px ;
}
.contact-detailsOne .contact-details-title{
    font-size: 40px;
    font-family: var(--headingFamily);
    padding-bottom: 20px;
}
.contact-detail-logoaddress{
    display: flex;
    padding-bottom: 17px;
}
.contact-detail-logoaddress .contact-icons{
    font-size: 20px;
    color: var(--primaryColor)
}
.contact-detail-logoaddress .contact-address-title{
    padding-left: 24px;
    color: var(--primaryColor);
    top: -5px;
    position: relative;
}
.contact-detail-logoaddress h6{
    font-size: 15px;
    color:#fff;
}
.contact-detail-logoaddress h6 a{
    color:#fff;
    text-decoration: none;
    transition: 0.4s all ease;
}
.contact-detail-logoaddress h6 a:hover{
    color: var(--primaryColor)
}






.contact-form h2{
    font-family: var(--headingFamily);
    font-size: 45px;
    /* padding-bottom: 5px; */

}
.contactform{
    padding-top: 20px;
}
.contact-form .inputfield, .textarea{
    width: 100%;
    border:0px;
    border-radius: 4px;
    background-color: #cfcccc;
    color:#fff!important;
    font-family: var(--textFamily);
    font-size: 17px;
    padding-left: 10px;
}
.contact-form .inputfield{
    height: 50px;
}
.contact-form .inputfield:focus, .textarea:focus{
    border: 1px solid var(--primaryColor)!important;
    outline:none;
}
.contact-form p{
    font-style:italic;
    font-size:16px;
    color:red;
}
.contact-form .contact-form-div, .textarea{
    padding-bottom: 20px;
}
.contact-submit-btn{
   position: relative;
   top:-15px;
}
.signUpButton{
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    font-family: var(--textFamily);
    color:#fff;
    font-weight: 700;
    background-color: var(--primaryColor);
    border-radius: 4px;
    transition: 0.4s all ease;
}
.signUpButton:hover{
    background-color: var(--mainBlue);
}
/* form success css */
.form-success{
    width: 100vw;
    height: 100vh; 
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)),url("https://res.cloudinary.com/hayteetech/image/upload/v1640532889/Omakwaenergy/formsucessbg_shilmp.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 999;
}
.form-success p{
    color: #fff;
    font-style:italic;
    font-size: 17px;
    text-align: center;
}
.success_text{
    color:#fff;
    text-align: center;
    font-size: 40px;
    padding-bottom: 50px;
}
.csr-container{
    padding: 40px 0px;
}
.csr-text{
    color: var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    line-height: 33px;
    padding-top: 20px;
    text-align: justify;
}
.csr-background{
    margin-top: 30px;
}
.csr-img img{
    width: 90%;
    height: 230px;
    border-radius: 10px;
    opacity: 0.8;
    padding-bottom: 30px;
}
.csr-details{
    padding: 5px 0px 40px 0px;
}
.csr-background .row{
    padding-top: 40px;
}
.homepage{
    padding: 30px 0px;
    background:rgb(227, 236, 227);
 
}
.home-card{
    position: relative;
    margin: 40px 0px;
}
.home-card-content{
    text-align: center;
    color:#fff;
    font-family: var(--textFamily);
}
.home-card-content .card{
    padding: 20px 0px;
    height: 360px;
    transition: 0.4s all ease
}
.home-card-content .card:hover{
    opacity: 0.7
}
.home-card-content .card1{
    background: var(--primaryColor)
}
.home-card-content .card2{
    background-color:  #2b479c 
}
.home-card-content .card3{
    background: rgb(37, 88, 37);
}
.card-body .card-icon{
    font-size: 30px;
}
.card-body .card-title{
    padding: 20px 0px 10px 0px;
    font-size: 20px;
    font-weight: 600
}
.card-body .card-text{
    font-size: 18px;
    line-height: 32px
}
.card-body .btn{
    margin-top: 15px;
    border: 2px solid #fff;
    color:#fff;
    font-family: var(--textFamily);
    background: transparent;
    transition: 0.4s all ease;
}
.card-body .btn:hover{
    border: 2px solid rgb(226, 224);
    background: rgb(226, 224, 224);
    color: var(--blackColor)
}
.home-about-text{
    color:var(--blackColor);
    line-height: 30px;
    font-family: var(--textFamily);
    font-size: 17px;
    padding: 30px 0px 30px 5px;
    text-align: justify;
}
.home-about-text2{
    padding-top: 105px;
}

/* home flagship css starts here */
.home-flagship-container{
    padding: 30px 0px;
}
.home-flagship-container h1{
    text-align: center;
    justify-content: center;
}
.title-underline{
    padding-left: 37vw;
}
.home-flagship-subtitle{
    font-size: 25px;
    color: var(--blackColor);
    font-family: var(--headingFamily);
}
.home-flagship-row{
    padding: 40px 0px 20px 0px;
}
.home-text{
    color:var(--blackColor);
    font-family: var(--textFamily);
    font-size: 17px;
    line-height: 30px;
    padding: 10px 0px;
    text-align: justify;
}
.home-flagship-image-section{
    padding: 0px 30px;
    position: relative;
}
.home-flagship-image img{
    border-radius: 10px;
    width: 100%;
    height: 300px;
}
.home-flagship-image-text{
    position: absolute;
    text-align: right;
    font-family: var(--textFamily);
    color: #fff;
    font-size: 20px;
    top:-0px;
    padding-right: 45px;
    right:0px;
}
.home-flagship-image-text img{
   width: 60%;
   text-align: right;
   top:-30px;
   position: relative;
}

/* home service css starts here */
.home-service-container{
    background:#fff;
    padding: 30px 0px;
    margin: 20px 0px 30px 0px
}
.home-service h1{
    text-align: center;
    justify-content: center;
}
.home-service-text{
    color: var(--blackColor);
    font-family: var(--textFamily);
    text-align:center;
    max-width: 1050px;    
    font-size: 18px;
    padding-left: 10vw;
    padding-bottom: 40px;
    padding-top: 20px;
}
.home-service-row{
    padding: 20px 0px 30px 0px;
}
.home-service-grid-style{  
    justify-content: center!important;
    padding:20px 5px;
    height: 170px;
    cursor:pointer;
    transition: 0.4s all ease;
    opacity:1;
    position:relative;
}
.home-service-nav{
    text-decoration: none;
}
.home-service-grid-style:hover{
    opacity: 0.5;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
}
.home-service-grid-style:hover img{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.home-service-grid-style1,.home-service-grid-style3 {
    background-color:  rgb(37, 88, 37);
}
.home-service-grid-style2, .home-service-grid-style4{
    background-color: #2b479c;
}
.home-service-grid-style .img{
    background: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:0 auto; 
    transition: 0.4s all ease;  
}
.home-service-grid-style img{
     height:50px;
     width: 50px;
     transition: 0.4s all ease; 
}
.home-service-grid-style h4{
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
    color:#fff;
    padding: 20px 10px 10px 10px;
    font-family: var(--textFamily);
}
/* another section starts here */
.home-picture{
    background:var(--mainBlue);
    padding: 50px 0px;
    color:#fff;
    font-family: var(--textFamily);
}
.home-picture img{
    width:100%;
    padding-bottom: 10px;
}
/* .home-signup something  start here*/
.home-container-signup{
    padding:30px 200px;
}
.home-signup-title h1{
    color:#fff;   
}
.home-signup{
    border: 2px solid var(--mainBlue);
    padding: 30px 30px;  
    text-align: center;
    border-radius: 10px;
}

/* home-contact starts here */
.home-contact{
    background:var(--primaryColor);
    padding: 30px 0px;
    color:#fff
}
.home-contact .home-text{
    color:#fff
}
.home-contact .button-navlink .nav{
    border:2px solid  var(--mainBlue);
    color: #fff;
   
}
.home-contact .button-navlink .nav:hover{
    background: var(--mainBlue);
    border:2px solid transparent;
    color: #fff;
}
.home-contact-container{
    background: var(--mainBlue);
    text-align: center;
    color:#fff; 
    font-family:var(--textFamily) ;
    text-transform: capitalize;
    padding-bottom: 10px; 
}
.home-contact-container h3{
    padding: 20px 0px;
    line-height: 50px;   
}
.home-contact-container h6{
    padding-bottom: 15px;
}
.home-contact-container .nav{
    color:var(--primaryColor);
}
.button-navlink span{
    padding-left: 15px;
    font-size: 20px;
    transition: 0.4s all ease;
}

/* media screen css */

@media screen and (max-width: 960px){
    .home-container-signup{
        padding:30px 100px;
    }
    .home-about-text2{
        padding-top: 10px;
    }
    .home-card{
        position: relative;
        top: 0px;
    }
    .home-card-content .card{
        padding: 20px 0px;
        height:auto;
    }
}

@media screen and (max-width: 760px){
    .home-container-signup{
        padding:30px 20px;
    }
    .home-signup{
        border: 2px solid var(--mainBlue);
        padding: 30px 5px;  
        text-align: left;
        border-radius: 10px;
    }
    .home-signup-title h1{
        text-align: center;  
    }
    .home-signup .home-text{
        font-size: 15px;
    }
    .title-underline{
        padding-left: 34vw;
    }
  
}

.first-slide{
    background: var(--mainBlue);
    color: #fff;
    padding: 60px 0px;
    height: 400px;
}
.react-slick-title, .react-slick-title1{
    font-size: 45px;
    font-family: var(--textFamily);
    text-transform: capitalize;
    font-weight: 600;
    text-align: left;
}

.dynamic-txts{
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-align: left;
    margin: 0;
    padding: 0;
    display: inline-block;
}
.dynamic-txts li{
    list-style: none;
    font-size: 48px;
    font-weight: 600;
    position: relative;
    padding: 7px 0px;
    text-align: left;
    color: var(--primaryColor);
    font-family: var(--textFamily);
    top: -3px;
    -webkit-animation: slide 11s steps(3) infinite;
            animation: slide 11s steps(3) infinite;
    transition: 0.5s all ease;
}
@-webkit-keyframes slide {
    100%{
        top: -200px;
    }
}
@keyframes slide {
    100%{
        top: -200px;
    }
}

.Slider{
    overflow: hidden;
    width: 100%;
}
.background-pictures{
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.background-image1{
    background: url("https://res.cloudinary.com/hayteetech/image/upload/v1640530974/Omakwaenergy/image1_gkjzis.jpg")
}
.background-image2{
    background: url("https://res.cloudinary.com/hayteetech/image/upload/v1648056716/Omakwaenergy/omakwabg2_tiqe29.jpg")
}

.background-image4{
    background: url("https://res.cloudinary.com/hayteetech/image/upload/v1640530975/Omakwaenergy/image2_n5mfnp.jpg");
}
.background-image5{
    background: url("https://res.cloudinary.com/hayteetech/image/upload/v1648056716/Omakwaenergy/omakwabg5_fxotvn.jpg")
}
@media screen and (max-width: 960px){
    .first-slide{
        height: 300px;
        padding: 40px 0px;
    }
    .wrapper{
        max-width: 500px;
        margin: auto!important
    }
    .background-pictures{
        height: 300px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .react-slick-title1{
        position: relative;
        top: -8px!important;
       
    }
}

@media screen and (max-width: 780px){
    .react-slick-title{
        font-size: 30px;
        font-family: var(--textFamily);
        text-transform: capitalize;
        font-weight: 600;
        text-align:left;
    }
    .react-slick-title1{
        position: relative;
        top: -10px!important;
        font-size: 30px;
    }
    .dynamic-txts li{
        list-style: none;
        font-size: 35px;
        
    }
    .background-pictures{
        height: 250px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}
.media-container{
    padding: 40px 0px;
}
.media-container p{
    color: var(--textColor);
    font-family: var(--textFamily);
    font-style: italic;
    font-size: 17px;
}

/* css for the tabs starts here */

  
.bloc-tabs {
    display: flex;
    margin-left: 20vw;
}
.tabs {
    padding: 15px;
    text-align: center;
    width: 30%;
    background: var(--backgroundColor);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    transition: 0.4s all ease;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
}
.tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}
.active-tabs  {
background: var(--primaryColor);
border-bottom: 1px solid transparent;
color:#fff;
}
.active-tabs::before {
content: "";
display: block;
position: absolute;
top: -5px;
left: 50%;
-webkit-transform: translateX(-50%);
        transform: translateX(-50%);
width: calc(100% + 2px);
height: 5px;
background: var(--mainBlue);
}

.content-tabs {
/* flex-grow : 1; */
}
.content {
width: 100%;
display: none;
}

.active-content {
display: block;
}

/* css code for the pictures */
.grid-image{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-columns: repeat(auto-fit, minmax(380px, 2fr));
    padding-top: 20px; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    grid-gap:15px;
    
}
.mediaimg{    
    width:100%;
    margin:0 auto;
    position: relative;
    overflow:hidden;
    background: transparent;
    padding:10px 0px 0px 0px;
}
.media-img img{
    border-radius: 10px;
    width: 100%;
    height: 350px;
}
.mediaimg:hover .layer-className{
    top:0;
}
.mediaimg .layer-className{
    position: absolute;
    top: calc(100% - 3px);
    left:0;
    width: 100%;
    height: 360px;
    background: linear-gradient(180deg, rgba(81, 216, 27, 0.443), rgba(129, 216, 58, 0.443));
    z-index:1;
    transition:0.5s
}
.media-title{
    position:absolute;
    bottom:0px;
    padding: 20px 10px;
    background: linear-gradient(180deg, rgba(4, 5, 3, 0.443), rgba(0, 0, 0, 0.443));
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: var(--textFamily);
    color:#fff;
    text-transform: capitalize;
}

/* css for the pagination btn */
.paginationBttns{
    width: 100%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.paginationBttns a{
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    border:1px solid var(--mainBlue);
    color: var(--mainBlue);
    cursor:pointer;
    transition:0.4s all ease ;
    text-decoration: none;
}
.paginationBttns a:hover{
    color:#fff!important;
    background: var(--primaryColor)
}
.paginationActive a{
    background: var(--primaryColor);
    color:#fff!important;
}
.oil-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1640530975/Omakwaenergy/image2_n5mfnp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}

.environmental-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1640532243/Omakwaenergy/media3_khjqku.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.dredging-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1649024697/Omakwaenergy/dredging_fh6rj4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.civil-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1649025060/Omakwaenergy/civil_tjamge.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.otherservices-page{
    background: var(--backgroundColor);
    padding: 40px 0px;
}
.otherservices-title{
    padding-bottom: 40px;
}
.otherservice-link{
    text-decoration: none;
}
.service-card{
    display: flex;
    width:100%;
    justify-content: space-evenly;
}
.service-section{
    width:100%!important;
    padding: 0px 20px;
    position: relative;
}
.service-card img{
    width:100%;
    border-radius: 5px;
    height: 250px;
    transition: 0.4s all ease;
}
.service-section:hover img{
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
}
.servicescontainer{
    padding: 60px 0px;
}
.service-title{
    position: relative;
    top: -36px;
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443));
    color: #fff;
    font-family: var(--textFamily);
    padding: 7px 0px;
    font-weight: 700;
    padding-left: 10px;
}
@media screen and (max-width:760px){
    .section-noshow{
        display: none
    }
    .service-section{
        padding: 0px 20px;
        position: relative;
    }
    .service-title{
        position:relative;
        top: -30px;
        padding:7px 0px;
    }
}
@media screen and (max-width:900px){
    .service-section{
        padding: 0px 10px;
        position: relative;
    }
    .service-title{
        position: relative;
        top: -43px;
        font-size: 15px;
        padding: 5px 0px;
    }
}

/* other services section finish above */
.services-text{
    font-family: var(--textFamily);
    color: var(--textColor);
    font-size:17px;
    line-height: 35px;
    text-align: justify;
    padding: 10px;
}
.services-text span{
    color:var(--primaryColor);
    font-size: 18px;
}
.services-text li{
    font-size: 17px;
}
.service-other{
    margin-top: 30px;
}
/* error css */
.errorpage{
    background: linear-gradient(180deg, rgba(91, 92, 91, 0.443), rgba(36, 82, 10, 0.443)) ,url('https://res.cloudinary.com/hayteetech/image/upload/v1640530974/Omakwaenergy/image1_gkjzis.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    align-items: center;
    justify-content: center;
}
.errorpage h1{
    text-align: center;
    position: absolute;
    left:50%;
    top:40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 60px;
    letter-spacing: 1px;
}
.errornav{
    color: var(--mainBlue);
    text-decoration: none;
    transition:0.4s all ease
}
.errornav:hover{
    color: var(--blackColor);
    text-decoration: none;
}
