.contact-container{
    padding: 40px 0px;
}
.contact-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1648066466/Omakwaenergy/contactbg_bgn62i.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.contact-details{
    background-color: var(--mainBlue);
    color:#fff;
}
.contact-detailsOne{
    padding: 20px ;
}
.contact-detailsOne .contact-details-title{
    font-size: 40px;
    font-family: var(--headingFamily);
    padding-bottom: 20px;
}
.contact-detail-logoaddress{
    display: flex;
    padding-bottom: 17px;
}
.contact-detail-logoaddress .contact-icons{
    font-size: 20px;
    color: var(--primaryColor)
}
.contact-detail-logoaddress .contact-address-title{
    padding-left: 24px;
    color: var(--primaryColor);
    top: -5px;
    position: relative;
}
.contact-detail-logoaddress h6{
    font-size: 15px;
    color:#fff;
}
.contact-detail-logoaddress h6 a{
    color:#fff;
    text-decoration: none;
    transition: 0.4s all ease;
}
.contact-detail-logoaddress h6 a:hover{
    color: var(--primaryColor)
}






.contact-form h2{
    font-family: var(--headingFamily);
    font-size: 45px;
    /* padding-bottom: 5px; */

}
.contactform{
    padding-top: 20px;
}
.contact-form .inputfield, .textarea{
    width: 100%;
    border:0px;
    border-radius: 4px;
    background-color: #cfcccc;
    color:#fff!important;
    font-family: var(--textFamily);
    font-size: 17px;
    padding-left: 10px;
}
.contact-form .inputfield{
    height: 50px;
}
.contact-form .inputfield:focus, .textarea:focus{
    border: 1px solid var(--primaryColor)!important;
    outline:none;
}
.contact-form p{
    font-style:italic;
    font-size:16px;
    color:red;
}
.contact-form .contact-form-div, .textarea{
    padding-bottom: 20px;
}
.contact-submit-btn{
   position: relative;
   top:-15px;
}
.signUpButton{
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    font-family: var(--textFamily);
    color:#fff;
    font-weight: 700;
    background-color: var(--primaryColor);
    border-radius: 4px;
    transition: 0.4s all ease;
}
.signUpButton:hover{
    background-color: var(--mainBlue);
}
/* form success css */
.form-success{
    width: 100vw;
    height: 100vh; 
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)),url("https://res.cloudinary.com/hayteetech/image/upload/v1640532889/Omakwaenergy/formsucessbg_shilmp.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 999;
}
.form-success p{
    color: #fff;
    font-style:italic;
    font-size: 17px;
    text-align: center;
}
.success_text{
    color:#fff;
    text-align: center;
    font-size: 40px;
    padding-bottom: 50px;
}