.media-container{
    padding: 40px 0px;
}
.media-container p{
    color: var(--textColor);
    font-family: var(--textFamily);
    font-style: italic;
    font-size: 17px;
}

/* css for the tabs starts here */

  
.bloc-tabs {
    display: flex;
    margin-left: 20vw;
}
.tabs {
    padding: 15px;
    text-align: center;
    width: 30%;
    background: var(--backgroundColor);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    transition: 0.4s all ease;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
}
.tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}
.active-tabs  {
background: var(--primaryColor);
border-bottom: 1px solid transparent;
color:#fff;
}
.active-tabs::before {
content: "";
display: block;
position: absolute;
top: -5px;
left: 50%;
transform: translateX(-50%);
width: calc(100% + 2px);
height: 5px;
background: var(--mainBlue);
}

.content-tabs {
/* flex-grow : 1; */
}
.content {
width: 100%;
display: none;
}

.active-content {
display: block;
}

/* css code for the pictures */
.grid-image{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-columns: repeat(auto-fit, minmax(380px, 2fr));
    padding-top: 20px; 
    height: fit-content;
    grid-gap:15px;
    
}
.mediaimg{    
    width:100%;
    margin:0 auto;
    position: relative;
    overflow:hidden;
    background: transparent;
    padding:10px 0px 0px 0px;
}
.media-img img{
    border-radius: 10px;
    width: 100%;
    height: 350px;
}
.mediaimg:hover .layer-className{
    top:0;
}
.mediaimg .layer-className{
    position: absolute;
    top: calc(100% - 3px);
    left:0;
    width: 100%;
    height: 360px;
    background: linear-gradient(180deg, rgba(81, 216, 27, 0.443), rgba(129, 216, 58, 0.443));
    z-index:1;
    transition:0.5s
}
.media-title{
    position:absolute;
    bottom:0px;
    padding: 20px 10px;
    background: linear-gradient(180deg, rgba(4, 5, 3, 0.443), rgba(0, 0, 0, 0.443));
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: var(--textFamily);
    color:#fff;
    text-transform: capitalize;
}

/* css for the pagination btn */
.paginationBttns{
    width: 100%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.paginationBttns a{
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    border:1px solid var(--mainBlue);
    color: var(--mainBlue);
    cursor:pointer;
    transition:0.4s all ease ;
    text-decoration: none;
}
.paginationBttns a:hover{
    color:#fff!important;
    background: var(--primaryColor)
}
.paginationActive a{
    background: var(--primaryColor);
    color:#fff!important;
}