.oil-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1640530975/Omakwaenergy/image2_n5mfnp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}

.environmental-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1640532243/Omakwaenergy/media3_khjqku.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.dredging-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1649024697/Omakwaenergy/dredging_fh6rj4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.civil-bg{
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443)), url("https://res.cloudinary.com/hayteetech/image/upload/v1649025060/Omakwaenergy/civil_tjamge.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
}
.otherservices-page{
    background: var(--backgroundColor);
    padding: 40px 0px;
}
.otherservices-title{
    padding-bottom: 40px;
}
.otherservice-link{
    text-decoration: none;
}
.service-card{
    display: flex;
    width:100%;
    justify-content: space-evenly;
}
.service-section{
    width:100%!important;
    padding: 0px 20px;
    position: relative;
}
.service-card img{
    width:100%;
    border-radius: 5px;
    height: 250px;
    transition: 0.4s all ease;
}
.service-section:hover img{
    transform: scale(1.06);
}
.servicescontainer{
    padding: 60px 0px;
}
.service-title{
    position: relative;
    top: -36px;
    background: linear-gradient(180deg, rgba(66, 182, 72, 0.443), rgba(36, 82, 10, 0.443));
    color: #fff;
    font-family: var(--textFamily);
    padding: 7px 0px;
    font-weight: 700;
    padding-left: 10px;
}
@media screen and (max-width:760px){
    .section-noshow{
        display: none
    }
    .service-section{
        padding: 0px 20px;
        position: relative;
    }
    .service-title{
        position:relative;
        top: -30px;
        padding:7px 0px;
    }
}
@media screen and (max-width:900px){
    .service-section{
        padding: 0px 10px;
        position: relative;
    }
    .service-title{
        position: relative;
        top: -43px;
        font-size: 15px;
        padding: 5px 0px;
    }
}

/* other services section finish above */
.services-text{
    font-family: var(--textFamily);
    color: var(--textColor);
    font-size:17px;
    line-height: 35px;
    text-align: justify;
    padding: 10px;
}
.services-text span{
    color:var(--primaryColor);
    font-size: 18px;
}
.services-text li{
    font-size: 17px;
}
.service-other{
    margin-top: 30px;
}